import { useEffect, useState } from 'react';

import classNames from 'classnames';

import BpkChevronDown from '@skyscanner/backpack-web/bpk-component-icon/sm/chevron-down';
import BpkLink from '@skyscanner/backpack-web/bpk-component-link';

import type { CustomNavMenuItem } from '@skyscanner-internal/falcon-shared-types/types/CustomNavProps';

import STYLES from './CustomNav.module.scss';

type CustomNavDesktopItemProps = {
  menuItem: CustomNavMenuItem;
  onOpen: React.Dispatch<React.SetStateAction<string | null>>;
  openMenuItemId: string | null;
};

const HOVER_DELAY_OPEN = 100;
const HOVER_DELAY_CLOSE = 200;

export default function CustomNavDesktopItem({
  menuItem,
  onOpen,
  openMenuItemId,
}: CustomNavDesktopItemProps) {
  const [hoverOpen, setHoverOpen] = useState(false);

  // Adds delay to opening & closing on hover the menu as recommended by https://www.w3.org/WAI/tutorials/menus/flyout/
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    if (hoverOpen) {
      timer = setTimeout(() => {
        onOpen(menuItem.id);
      }, HOVER_DELAY_OPEN);
    } else {
      timer = setTimeout(() => {
        onOpen((curr) => {
          // Prevents closing the current sub-menu if it changed during the delay
          if (curr === menuItem.id) {
            return null;
          }

          return curr;
        });
      }, HOVER_DELAY_CLOSE);
    }

    return () => clearTimeout(timer);
  }, [hoverOpen, menuItem.id, onOpen]);

  const toggleMenu = (itemId: string) => {
    if (openMenuItemId !== itemId) {
      onOpen(menuItem.id);
    } else {
      onOpen(null);
    }
  };

  if (!menuItem.subMenuItems?.length) {
    return (
      <div key={menuItem.id} className={STYLES.MenuItem}>
        <BpkLink href={menuItem.href} alternate>
          {menuItem.label}
        </BpkLink>
      </div>
    );
  }

  return (
    <div
      key={menuItem.id}
      className={STYLES.MenuItem}
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      onMouseOver={() => {
        setHoverOpen(true);
      }}
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      onMouseOut={() => {
        setHoverOpen(false);
      }}
    >
      <BpkLink
        href={menuItem.href}
        aria-expanded={openMenuItemId === menuItem.id}
        alternate
      >
        {menuItem.label}
      </BpkLink>

      <span
        role="button"
        tabIndex={0}
        className={STYLES.Chevron}
        aria-expanded={openMenuItemId === menuItem.id}
        aria-label={`show submenu for ${menuItem.label}`}
        onKeyDown={(e) => {
          if (e.key !== 'Enter') {
            return;
          }

          toggleMenu(menuItem.id);
        }}
        onClick={(e: any) => toggleMenu(menuItem.id)}
      >
        <BpkChevronDown />
      </span>

      <div
        data-testid="news-nav-sub-menu"
        className={classNames([
          STYLES.SubMenu,
          openMenuItemId === menuItem.id && STYLES.SubMenuOpen,
        ])}
      >
        <div className={STYLES.SubMenuInner}>
          {menuItem.subMenuItems.map((subMenuItem) => (
            <div key={subMenuItem.id} className={STYLES.SubMenuItem}>
              <BpkLink href={subMenuItem.href} alternate>
                {subMenuItem.label}
              </BpkLink>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
