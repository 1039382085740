import {
  BpkAccordion,
  BpkAccordionItem,
  withAccordionItemState,
} from '@skyscanner/backpack-web/bpk-component-accordion';
import BpkLink from '@skyscanner/backpack-web/bpk-component-link';
import { TEXT_STYLES } from '@skyscanner/backpack-web/bpk-component-text';
import BpkText from '@skyscanner/backpack-web/bpk-component-text/src/BpkText';

import type { CustomNavMenuItem } from '@skyscanner-internal/falcon-shared-types/types/CustomNavProps';

import STYLES from './CustomNav.module.scss';

type CustomNavMobileItemProps = {
  menuItem: CustomNavMenuItem;
  mobileVisitParentPageLabel: string;
};

const StatefulAccordionItem = withAccordionItemState(BpkAccordionItem);

export default function CustomNavMobileItem({
  menuItem,
  mobileVisitParentPageLabel,
}: CustomNavMobileItemProps) {
  if (!menuItem.subMenuItems?.length) {
    return (
      <div key={menuItem.id} className={STYLES.SingleMenuItem}>
        <BpkText textStyle={TEXT_STYLES.bodyLongform}>
          <BpkLink href={menuItem.href} alternate>
            {menuItem.label}
          </BpkLink>
        </BpkText>
      </div>
    );
  }

  return (
    <BpkAccordion key={menuItem.id} onDark>
      <StatefulAccordionItem
        id={menuItem.id}
        title={menuItem.label}
        textStyle="body-longform"
      >
        <div className={STYLES.SubMenu}>
          <div className={STYLES.SubMenuItem}>
            <BpkLink href={menuItem.href} alternate>
              {mobileVisitParentPageLabel.replace('@@title@@', menuItem.label)}
            </BpkLink>
          </div>

          {menuItem.subMenuItems.map((subMenuItem) => (
            <div key={subMenuItem.id} className={STYLES.SubMenuItem}>
              <BpkLink href={subMenuItem.href} alternate>
                {subMenuItem.label}
              </BpkLink>
            </div>
          ))}
        </div>
      </StatefulAccordionItem>
    </BpkAccordion>
  );
}
